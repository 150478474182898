import React from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Link from "../components/link"

export const portfolioQuery = graphql`
  {
    portfolio: contentfulPortfolio {
      items: portfolioItems {
        id
        title
        image {
          gatsbyImageData(
            height: 400
            width: 600
            layout: FIXED
            placeholder: BLURRED
            formats: [WEBP, PNG]
          )
        }
        fields {
          slug
        }
      }
    }
  }
`
export default function PortfolioPage({ data: { portfolio } }) {
  return (
    <>
      <Seo title="Portfolio" image={portfolio.items[0].image.src} />
      <PortfolioPageContainer>
        {portfolio.items.map(item => (
          <Card key={item.id} as={Link} to={item.fields.slug}>
            <GatsbyImage
              image={getImage(item.image)}
              placeholder="BLURRED"
              style={{ width: "100%" }}
            />
            <h3>{item.title}</h3>
          </Card>
        ))}
      </PortfolioPageContainer>
    </>
  )
}

const PortfolioPageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 6rem 0;
  @media screen and (max-width: 1170px) {
    margin: 6rem 5vw;
  }
  @media screen and (max-width: 780px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`
const Card = styled.div`
  flex: 0 0 calc(33.33% - 2rem);
  margin-bottom: 2rem;

  &:not(:nth-child(3n)) {
    margin-right: 2rem;
  }

  img {
    width: 100%;
    border-radius: 5px 5px 0 0;
  }
  h3 {
    background: #000;
    color: #fff;
    padding: 1.5rem 2rem;
    font-weight: 400;
    border-radius: 0 0 5px 5px;
  }
  @media screen and (max-width: 780px) {
    flex: 0 0 calc(50% - 0.75rem);
    margin-right: 0 !important;
    margin-bottom: 1.5rem;
    &:not(:nth-child(2n)) {
      margin-right: 1.5rem !important;
    }
  }
  @media screen and (max-width: 510px) {
    flex: 0 0 100%;
    margin-right: 0 !important;
    margin-bottom: 2rem;
  }
  @media screen and (max-width: 480px) {
    h3 {
      font-size: 2.4rem;
    }
  }
`
